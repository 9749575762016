import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AuthService, UNAUTHENTICATED_RESPONSE_MESSAGES } from "../services/auth.service";
import { NotificationMessageService } from "../services/notification.message.service";
import { ValidationError } from "src/app/shared/interfaces/validation-error.interface";
import { ObservableService } from "../services/observable.service";

@Injectable({ providedIn: "root" })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationMessageService,
    private observableService: ObservableService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0) as any,
      catchError((error: HttpErrorResponse) => {
        const error_response: ErrorModel = error.error;
        // there's no token or token not valid
        this.observableService.setValidationErrors(error_response.validation_errors || {});
        if (error.url?.includes("logout")) {
          this.authService.clearUserData();
        }
        if (error.status === 403) {
          if (
            !this.authService.UserToken ||
            error_response.message === UNAUTHENTICATED_RESPONSE_MESSAGES.refresh_expired ||
            error_response.detail === UNAUTHENTICATED_RESPONSE_MESSAGES.refresh_blocked
          ) {
            this.authService.logout();
            this.notificationService.showErrorMessage(error_response.detail);
          }

          // NOTE: else will fire refresh token so no need for showing any error message to user
        } else if (error_response.message || error_response.detail) {
          this.notificationService.showErrorMessage(
            error_response.message ?? error_response.detail,
          );
        } else if (
          error_response.validation_errors &&
          error_response.validation_errors.non_field_errors
        ) {
          this.notificationService.showErrorMessage(
            error_response.validation_errors.non_field_errors[0],
          );
        }
        return throwError(() => error);
      }),
    );
  }
}

export interface ErrorModel {
  code: string;
  message: string;
  detail: string;
  validation_errors: ValidationError;
}
